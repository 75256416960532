import {lazy, Suspense} from 'react';
import {Route, BrowserRouter} from 'react-router-dom';
import {App, ConfigProvider} from 'antd';

import './styles/App.scss';
import {Loader} from './components';
import RoutesWithNotFound from './guards/RoutesWithNotFound';

const theme = {
	token: {
		colorPrimary: '#722ED1',
		fontFamily: 'var(--poppins)',
		fontSizeHeading1: 44,
		fontSizeHeading2: 32,
		colorLink: '#722ED1',
		colorLinkHover: '#722ED1',
		lineHeightHeading1: 56,
		borderRadius: 6
	}
};

const Home = lazy(() => import('./screens/home/Home'));
const Schedule = lazy(() => import('./screens/scheduler/index'));

function MyApp() {
	return (
		<Suspense fallback={<Loader />}>
			<ConfigProvider theme={theme}>
				<App>
					<BrowserRouter>
						<RoutesWithNotFound>
							<Route path='/' element={<Home />} />
							<Route path='/schedule' element={<Schedule />} />
						</RoutesWithNotFound>
					</BrowserRouter>
				</App>
			</ConfigProvider>
		</Suspense>
	);
}

export default MyApp;
