import styles from './loader.module.scss';

const Loader = () => {
	return (
		<div className={styles.loader}>
			<img
				src={window.origin + '/assets/brand/logo.svg'}
				width='250px'
				alt='loader kunan logo'
			/>
		</div>
	);
};

export default Loader;
