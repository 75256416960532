import {lazy} from 'react';
import {Route, Routes} from 'react-router-dom';
import PropTypes from 'prop-types';
// import NotFound from '../screens/NotFound';

const NotFound = lazy(() => import('../screens/not-found.js/NotFound'));

const RoutesWithNotFound = ({children}) => {
	return (
		<Routes>
			{children}
			<Route path='*' element={<NotFound />} />
		</Routes>
	);
};

export default RoutesWithNotFound;

RoutesWithNotFound.propsTypes = {
	children: PropTypes.node.isRequired
};
